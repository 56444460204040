/* eslint-disable valid-typeof */
import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import { breakpointTablet } from "../../constants";

export default function useMobile() {
  const [isMobile, setIsMobile] = useState(null);

  const isMobileMediaQuery = useMediaQuery({
    query: `(max-width: ${breakpointTablet - 1}px)`,
  });

  const isMobileLandscapeMediaQuery = useMediaQuery({
    query: `(orientation:landscape) and (max-height: ${
      breakpointTablet - 1
    }px)`,
  });

  const isSSR = typeof document === undefined;

  useEffect(() => {
    if (isMobileLandscapeMediaQuery) {
      setIsMobile(null);
    } else {
      setIsMobile(
        !isSSR && (isMobileMediaQuery || isMobileLandscapeMediaQuery)
      );
    }
  }, [isMobileMediaQuery, isSSR, isMobileLandscapeMediaQuery]);

  return isMobile;
}
