import React from "react";
import loadable from "@loadable/component";
import classNames from "classnames";
import FormattedMessage from "helpers/formattedMessage";
import { useIntl } from "react-intl";

import imageDesktopX1 from "./assets/en-desktop.png";
import imageDesktopX2 from "./assets/en-desktop@2x.png";
import imageDesktopX3 from "./assets/en-desktop@3x.png";

import imageEsDesktopX1 from "./assets/es-desktop.png";
import imageEsDesktopX2 from "./assets/es-desktop@2x.png";
import imageEsDesktopX3 from "./assets/es-desktop@3x.png";

import styles from "./PreFooterCTA.module.scss";
import useMobile from "hooks/useMobile";

const BranchLink: any = loadable(
  () =>
    import(
      /* webpackChunkName: "components-BranchLink"              */ "../BranchLink"
    )
);

const Button: any = loadable(
  () =>
    import(/* webpackChunkName: "components-Button"              */ "../Button")
);

const QRCodeCard: any = loadable(
  () =>
    import(
      /* webpackChunkName: "components-QRCodeCard"              */ "../QRCodeCard"
    )
);

export interface Props {
  className?: string;
}

function PreFooterCTA({ className }: Props): JSX.Element {
  const intl = useIntl();

  const isMobile = useMobile();

  return (
    <aside
      className={classNames(styles.wrapper, {
        [styles.hidden]: className,
      })}
      data-testid="pre-footer-cta"
    >
      <div className={styles.container}>
        <div>
          <header>
            <h4>
              <FormattedMessage
                defaultMessage="<s>Want to meet</s> women like you?"
                description="Pre Footer CTA Title"
                id="components.prefootercta.title"
                values={{
                  s: (chunks) => <span>{chunks}</span>,
                }}
              />
            </h4>
            <p>
              <FormattedMessage
                defaultMessage="Download Peanut to connect with women at a similar stage in life."
                description="Pre Footer CTA Description"
                id="components.prefootercta.description"
              />
            </p>
          </header>

          {!isMobile && (
            <div className={styles.prefooterQRCodeCard}>
              <QRCodeCard />
            </div>
          )}

          <div className={styles.ctaButton}>
            <BranchLink>
              <Button className={styles.actionButton}>
                <FormattedMessage
                  defaultMessage="Try today for free"
                  description="Pre Footer CTA button text"
                  id="component.prefootercra.button"
                />
              </Button>
            </BranchLink>
          </div>

          <footer className={styles.footer}>
            <div>
              <span>
                <FormattedMessage
                  defaultMessage="Rated 4.3"
                  description=""
                  id="components.prefootercta.trustrating.score"
                />
              </span>
            </div>
            <FormattedMessage
              defaultMessage="Trusted by 5M+ women"
              description="Pre Footer Trust rating"
              id="components.prefootercta.trustrating.count"
            />
          </footer>
        </div>

        {intl.locale === "es" ? (
          <picture className={styles.image}>
            <source
              srcSet={`${imageEsDesktopX1}, ${imageEsDesktopX2} 2x, ${imageEsDesktopX3} 3x`}
              media="(min-width: 1200px)"
            />
            <source
              srcSet={`${imageEsDesktopX1}, ${imageEsDesktopX2} 2x, ${imageEsDesktopX3} 3x`}
              media="(max-width: 1200px)"
            />

            <img
              fetchpriority="low"
              loading="lazy"
              src={imageEsDesktopX1}
              width={364}
              height={343}
              alt={intl.formatMessage({
                defaultMessage:
                  "Download Peanut to connect with women at a similar stage in life.",
                description: "Pre Footer CTA Description",
                id: "components.prefootercta.description",
              })}
            />
          </picture>
        ) : (
          <picture className={styles.image}>
            <source
              srcSet={`${imageDesktopX1}, ${imageDesktopX2} 2x, ${imageDesktopX3} 3x`}
              media="(min-width: 1200px)"
            />
            <source
              srcSet={`${imageDesktopX1}, ${imageDesktopX2} 2x, ${imageDesktopX3} 3x`}
              media="(max-width: 1200px)"
            />

            <img
              fetchpriority="low"
              loading="lazy"
              src={imageDesktopX1}
              width={364}
              height={343}
              alt={intl.formatMessage({
                defaultMessage:
                  "Download Peanut to connect with women at a similar stage in life.",
                description: "Pre Footer CTA Description",
                id: "components.prefootercta.description",
              })}
            />
          </picture>
        )}
      </div>
    </aside>
  );
}

export default PreFooterCTA;
